<template>
  <div class="background" :style="getBackgroundCSS"></div>
  <a href="https://medau-therapie.de/karriere/stellenangebote"
     class="global-m"
     target="_blank"
     v-html="getSVG('global-m')"
  />
  <div class="main-wrapper-outer">
    <div class="main-wrapper-inner" v-if="pageData">
      <img :src="getImage('logo.png')" alt="Logo" class="main-logo" />
      <p class="main-text" v-html="getMainText" />
      <div class="link-wrapper">
        <a v-for="link in pageData.Link"
           :href="link.Link"
           target="_blank"
           :class="'link'+(link.Fett?' bold':'')"
        >{{ link.Text }}</a>
      </div>
      <div class="social-links">
        <a class="social-link"
           :href="pageData.InstagramLink"
           v-if="pageData.InstagramLink"
           v-html="getIcon('instagram')"
        />
        <a class="social-link"
           :href="pageData.FacebookLink"
           v-if="pageData.FacebookLink"
        >
          <img :src="getImage('facebook.png')" alt="facebook">
        </a>
        <a class="social-link"
           v-html="getIcon('linkedin')"
           :href="pageData.LinkedinLink"
           v-if="pageData.LinkedinLink"
        />
      </div>
      <p class="legal-links">
        <a href="https://medau-therapie.de/karriere/stellenangebote" target="_blank">Karriere bei Medau</a>
        | <a href="https://medau-therapie.de/seiten/impressum-gmbh" target="_blank">Impressum</a>
        | <a href="https://medau-therapie.de/seiten/datenschutz" target="_blank">Datenschutz</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageData: null,
    };
  },
  computed: {
    getMainText() {
      return this.pageData.IntroText.replaceAll("\n", "<br />");
    },
    getBackgroundCSS() {
      if(!this.pageData) {
        return;
      }
      let plainCSS = this.pageData.HintergrundCSS;
      plainCSS = plainCSS?.trim() ?? null;
      if(!plainCSS) {
        plainCSS = 'background: linear-gradient(100deg, rgba(0,0,0,1) 0%, rgba(50,175,132,1) 25%,' +
            ' rgba(19,165,219,1) 41%, rgba(101,56,141,1) 58%, rgba(218,72,143,1) 77%, rgba(0,0,0,1) 100%);';
      }
      return plainCSS;
    },
  },
  methods: {
    loadContents() {
      this.apiRequest('/medau-group?populate=deep')
          .then(cnt => {
            this.pageData = cnt?.data?.attributes ?? null;
          })
          .catch(() => this.pageData = false);
    },
  },
  mounted() {
    this.loadContents();
  }
}
</script>
