// External modules
import {createApp} from 'vue';
import store from './modules/store/index';
import cookies from 'vue-cookies';

// App-file
import App from './App.vue';

// Mixins
import functionsMixin from './modules/mixin/functions';
import configMixin from './modules/mixin/config';

// CSS
import './assets/css/style.css';
import './assets/css/sansation/css.css';

// Create app and configure router, mixins, plugins, etc.
createApp(App)
    .use(store)
    .use(cookies)
    .mixin(configMixin)
    .mixin(functionsMixin)
    .mount("#app");